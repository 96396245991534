import axios from '@axios'

export default {
    namespaced: true,
    state: {
        payments: [],
        pagination: {
            current_page: 1,
            total: 0,
            count: 20,
        },
        qr_stat: [],
    },
    getters: {},
    mutations: {
        SET_PAYMENTS(state, data) {
            state.payments = data
        },
        SET_PAGINATION(state, data) {
            state.pagination = data
        },
        SET_QR_STAT(state, data) {
            state.qr_stat = data
        },
        UPDATE_PAYMENT(state, data) {
            const index = state.payments.findIndex(e => e.id === data.id)
            if (index !== -1) {
                Object.assign(state.payments[index], data)
            }
        }
    },
    actions: {
        getPayments ({ commit }, query) {
            return new Promise((resolve, reject) => {
                axios.get(`onepay-idram-payments?${query}`)
                    .then(response => {
                        commit ('SET_PAYMENTS', response.data.data)
                        commit ('SET_PAGINATION', response.data.pagination)
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },
        getQRStat ({ commit }, query) {
            return new Promise((resolve, reject) => {
                axios.get(`onepay-idram-qrstat?${query}`)
                    .then(response => {
                        commit ('SET_QR_STAT', response.data)
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },
        setInspectDate ({ commit }, id) {
            return new Promise((resolve, reject) => {
                axios.post(`onepay-set-inspect-status`, {id: id})
                    .then(response => {
                        commit('UPDATE_PAYMENT', response.data)
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },

        reloadPayment({ commit }, id) {
           return new Promise((resolve, reject) => {
               axios.put(`onepay-idram-payments/${id}`)
                   .then(response => {
                       commit ('UPDATE_PAYMENT', response.data)
                       resolve(response)
                   })
                   .catch(error => {
                       reject(error)
                   })
           })
        }
    }
}
