<template>
  <b-card-code  id="idramPayments" no-body>

    <div class="page-header">
      <b-row>
        <b-col
            class="filtersContainer"
            md="12"
        >
          <div class="filters">
            <div class="filter-column">
              <v-select
                  id="vue-select"
                  v-model="filters.status"
                  :reduce="prefix => prefix.value"
                  placeholder="Կարգավիճակ"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="statuses"
                  @input="applyFilter"
              />
            </div>
            <div class="filter-column paymentsPicker" style="    min-width: auto;">
              <date-range-picker
                  ref="pickerNewAll"
                  v-model="dateRange"
                  :opens="'center'"
                  :locale-data="localeData"
                  :ranges="dateRanges"
                  :always-show-calendars="false"
                  @update="applyFilter"
              >
                <template
                    v-slot:input="pickerNew"
                    style="min-width: 350px;"
                >
                  <fa-icon class="header-icon calendarIcon" :icon="['far', 'calendar-alt']"/>
                </template>
              </date-range-picker>
            </div>
            <div class="filter-column">
              <b-form-input
                  id="name"
                  v-model="filters.CarRegNo"
                  type="text"
                  placeholder="Համարանիշ"
                  @change="applyFilter"
              />
            </div>
          </div>
        </b-col>
      </b-row>
    </div>

    <div class="qr-stat">
      <div v-for="(stat, index) in qr_stat" :key="index" class="stat">
        <div class="title">{{ stat.title }}</div>
        <div class="info">
          <div class="info-col">
            <div class="srvsc">
              <div v-for="(value, service) in stat.counts" class="ser">
                <span>{{ service_name(service) }}</span>
                <span class="mx-1"> - </span>
                <span>{{ value }} * {{ service_amount(service )}}</span>
              </div>
            </div>
          </div>
          <div class="info-col">
            <span>{{ stat.amount }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="payments table-responsive">
      <div
          v-for="(payment, index) in payments"
          :key="index"
          class="payment"
      >

        <div class="column font-size-15">
          <div class="user-info">
            <div class="item">{{ payment.created_at | formatDate }}</div>
            <div class="item">
              <fa-icon v-if="!['PAID', 'EXPIRED'].includes(payment.status)" @click="reloadPayment(payment)" style="color: rgb(100, 0, 220); font-size: 17px; position: relative; cursor: pointer; top: 7px;" icon="history" />
            </div>
          </div>
        </div>

        <div class="column font-size-15">
          {{ payment.request.vehicle_number }}
        </div>

        <div class="column">
          <div class="user-info">
            <div class="item">
              {{ payment.request.car }}
            </div>
          </div>
        </div>
        <div class="column">
          <div class="services">
            <div
                v-for="(service, sericeIndex) in payment.items"
                :key="sericeIndex"
                class="service"
            >
              <div class="item name">
                {{ service.service.translations[language].name }}
              </div>
              <div class="item amount">
                {{ service.amount }} դր.
              </div>
              <div
                  v-if="payment.status === 2"
                  class="item commission"
              >
                <fa-icon
                    icon="receipt"
                    @click="openReceipt(payment, service)"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="column">
          {{ total_amount(payment) }} դր.
        </div>
        <div class="column">
          <b-badge :variant="payment_status_badge(payment)">
            {{ payment_status_name(payment) }}
          </b-badge>
        </div>
      </div>
    </div>

    <div
        v-if="pagination"
        class="table-footer"
    >

      <!-- page length -->
      <b-form-group
          :label="`Գտնվել է ${pagination.total}`"
          label-cols="3"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1"
      />

      <!-- pagination -->
      <div>
        <b-pagination
            v-model="pagination.current_page"
            :total-rows="pagination.total"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
            @input="getPayments"
        >
          <template #prev-text>
            <feather-icon
                icon="ChevronLeftIcon"
                size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
                icon="ChevronRightIcon"
                size="18"
            />
          </template>
        </b-pagination>
      </div>
    </div>

    <b-modal
        id="receipt-modal"
        modal-class="modal-primary"
        centered
        size="sm"
        :hide-footer="true"
        title=""
    >
      <b-card-text v-if="selectedService">

        <div class="receipt-header">
          <div class="bank-logo">
            <img :src="require('@/assets/images/logo/evoca.png')">
          </div>
          <div class="order-info receipt-column">
            <div class="info">
              Գործարք/ամսաթիվ
            </div>
            <div class="info">
              N {{ selectedPayment.id }} / {{ selectedPayment.created_at | formatDate }}
            </div>
          </div>
        </div>
        <div class="receipt-body">
          <div class="order-info receipt-column">
            <div class="info">
              Սեփականատեր
            </div>
            <div class="info">
              {{ selectedPayment.name }}
            </div>

          </div>
          <div class="order-info receipt-column">
            <div class="info">
              Մեքենա
            </div>
            <div class="info">
              {{ selectedPayment.request.car }}
            </div>
          </div>
          <div class="order-info receipt-column">
            <div class="info">
              Հաշվ․ համարանիշ
            </div>
            <div class="info">
              {{ selectedPayment.request.car_reg_no }}
            </div>
          </div>
        </div>
        <div
            v-if="selectedService.service.name === 'TechnicalInspection'"
            class="receipt-station"
        >
          <div class="order-info receipt-column">
            <div class="info">
              ՏԶ կայան
            </div>
            <div class="info">
              {{ selectedPayment.partner.translations[language].name }}
            </div>
          </div>
        </div>
        <div class="receipt-service">
          <div class="order-info receipt-column">
            <div class="info">
              {{ selectedService.service.translations[language].name }}
            </div>
            <div class="info">
              <template v-if="selectedService.service.name === 'Property'">
                {{ selectedService.amount - selectedPayment.request.fine }} դր.
              </template>
              <template v-else>
                {{ selectedService.amount }} դր.
              </template>
            </div>
          </div>
          <div
              v-if="selectedService.service.name === 'Property' && selectedPayment.request.fine"
              class="order-info receipt-column"
          >
            <div class="info">
              Տույժ
            </div>
            <div class="info">
              {{ selectedPayment.request.fine }} դր.
            </div>
          </div>
          <div class="order-info receipt-column">
            <div class="info">
              Միջնորդավճար
            </div>
            <div class="info">
              {{ selectedService.commission }} դր.
            </div>
          </div>
        </div>
        <div class="receipt-total">
          <div class="order-info receipt-column">
            <div class="info">
              Ընդամենը վճարվել է
            </div>
            <div class="info">
              {{ selectedService.amount + selectedService.commission }} դր.
            </div>
          </div>
        </div>
        <div class="receipt-footer">
          <div
              class="print"
              @click="printReceipt"
          >
            Տպել
          </div>
        </div>
      </b-card-text>
    </b-modal>

  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import paymentsModule from '@/store/idram-payments/index'
import store from "@/store";
import vSelect from 'vue-select'
import {
  BRow, BCol, BFormInput, BFormGroup, BPagination, BBadge, BModal,
} from 'bootstrap-vue'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

let todayStart = new Date()
todayStart.setHours(0, 0, 0, 0)

let todayEnd = new Date()
todayEnd.setHours(23, 59, 59, 59)

export default {
  name: 'onepay-payments',
  components: {
    BCardCode,
    vSelect,
    BRow,
    BCol,
    BBadge,
    BFormInput,
    BFormGroup,
    BPagination,
    BModal,
    DateRangePicker
  },
  data() {
    return {
      PAYMENTS_MODULE_NAME: 'paymentsModuleIdram',
      language: 'hy',
      selectedService: null,
      filters: {
        CarRegNo: null,
        day: null
      },
      statuses: [
        {
          label: 'Մերժվել է',
          value: 3,
        },
        {
          label: 'Ստուգվում է',
          value: 1,
        },
        {
          label: 'Հաստատվել է',
          value: 2,
        },
      ],
      localeData: {
        applyLabel: 'Հաստատել',
        cancelLabel: 'Չեղարկել',
        ranges: [],
        firstDay: 1,
        format: 'dd/mm/yyyy',
        customRangeLabel: 'Այլ',
        daysOfWeek: ['Կիր', 'Երկ', 'Երք', 'Չոր', 'Հնգ', 'Ուրբ', 'Շբթ'],
        monthNames: ['Հուն', 'Փետ', 'Մար', 'Ապր', 'Մայ', 'Հուն', 'Հուլ', 'Օգ', 'Սեպ', 'Հոկ', 'Նոյ', 'Դեկ'],
        taxData: [],
      },
      dateRange: {
        startDate: todayStart,
        endDate: todayEnd,
      },
    }
  },
  computed: {
    pagination () {
      return this.$store.state[this.PAYMENTS_MODULE_NAME].pagination
    },
    payments () {
      return this.$store.state[this.PAYMENTS_MODULE_NAME].payments
    },
    qr_stat () {
      return this.$store.state[this.PAYMENTS_MODULE_NAME].qr_stat
    },
    total_amount() {
      return payment => payment.amount;
    },
    payment_status_badge() {
      return payment => {
        let variant = 'danger'
        // eslint-disable-next-line default-case
        switch (payment.status) {
          case '':
          case 'EXPIRED':
            variant = 'danger'
            break
          case 'NOTPAID':
            variant = 'warning'
            break
          case 'PAID':
            variant = 'success'
        }
        return variant
      }
    },
    payment_status_name() {
      return payment => {
        let name = ''
        // eslint-disable-next-line default-case
        switch (payment.status) {
          case 0:
          case 'EXPIRED':
            name = 'Մերժվել է'
            break
          case 'NOTPAID':
            name = 'Ստուգվում է'
            break
          case 'PAID':
            name = 'Հաստատվել է'
        }
        return name
      }
    },
    dateRanges() {
      // Today
      let today = new Date()

      let allStart = new Date('2010-01-01')

      let todayStart = new Date()
      todayStart.setHours(0, 0, 0, 0)

      let todayEnd = new Date()
      todayEnd.setHours(23, 59, 59, 59)

      // Yesterday
      let yesterdayStart = new Date()
      yesterdayStart.setDate(today.getDate() - 1)
      let yesterdayEnd = new Date()
      yesterdayEnd.setDate(today.getDate() - 1)

      // This week
      let thisWeekStart = new Date(new Date().setDate(new Date().getDate() + 1 - new Date().getDay()))
      let thisWeekEnd = new Date(new Date().setDate(new Date().getDate() + 1 - new Date().getDay() + 6))

      // Last Week
      let lastWeekStart = new Date(new Date().setDate(new Date().getDate() - 6 - new Date().getDay()))
      let lastWeekEnd = new Date(new Date().setDate(new Date().getDate() - new Date().getDay()))

      // This Month
      let thisMonthStart = new Date(new Date().getFullYear(), new Date().getMonth(), 1)
      let thisMonthEnd = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)

      // Last Month
      let lastMonthStart = new Date(today.getFullYear(), today.getMonth() - 1, 1)
      let lastMonthEnd = new Date(today.getFullYear(), today.getMonth(), 0)

      // This Quarter
      let currentMonth = today.getMonth()
      let currentYear = today.getFullYear()
      let thisQuarterStart
      let thisQuarterEnd
      let lastQuarterStart
      let lastQuarterEnd
      if (currentMonth >= 0 && currentMonth < 3) {
        thisQuarterStart = new Date(today.getFullYear(), 0, 1)
        thisQuarterEnd = new Date(new Date().getFullYear(), 2, 31)

        lastQuarterStart = new Date(today.getFullYear() - 1, 9, 1)
        lastQuarterEnd = new Date(today.getFullYear() - 1, 11, 31)
      } else if (currentMonth >= 3 && currentMonth < 6) {
        thisQuarterStart = new Date(today.getFullYear(), 3, 1)
        thisQuarterEnd = new Date(new Date().getFullYear(), 5, 30)

        lastQuarterStart = new Date(today.getFullYear(), 0, 1)
        lastQuarterEnd = new Date(new Date().getFullYear(), 2, 31)
      } else if (currentMonth >= 6 && currentMonth < 9) {
        thisQuarterStart = new Date(today.getFullYear(), 6, 1)
        thisQuarterEnd = new Date(new Date().getFullYear(), 8, 30)

        lastQuarterStart = new Date(today.getFullYear(), 3, 1)
        lastQuarterEnd = new Date(new Date().getFullYear(), 5, 30)
      } else if (currentMonth >= 9 && currentMonth < 12) {
        thisQuarterStart = new Date(today.getFullYear(), 9, 1)
        thisQuarterEnd = new Date(new Date().getFullYear(), 11, 31)

        lastQuarterStart = new Date(today.getFullYear(), 3, 1)
        lastQuarterEnd = new Date(new Date().getFullYear(), 5, 30)
      }

      let thisSemesterStart
      let thisSemesterEnd
      let lastSemesterStart
      let lastSemesterEnd
      if (currentMonth >= 0 && currentMonth < 5) {
        thisSemesterStart = new Date(today.getFullYear(), 0, 1)
        thisSemesterEnd = new Date(new Date().getFullYear(), 5, 30)

        lastSemesterStart = new Date(today.getFullYear() - 1, 6, 1)
        lastSemesterEnd = new Date(today.getFullYear() - 1, 11, 31)

      } else {
        thisSemesterStart = new Date(today.getFullYear(), 6, 1)
        thisSemesterEnd = new Date(new Date().getFullYear(), 11, 31)

        lastSemesterStart = new Date(today.getFullYear(), 0, 1)
        lastSemesterEnd = new Date(today.getFullYear(), 5, 30)
      }

      // This Year
      let thisYearStart = new Date(new Date().getFullYear(), 0, 1)
      let thisYearEnd = new Date(new Date().getFullYear(), 11, 31)

      // Last Year
      let lastYearStart = new Date(new Date().getFullYear() - 1, 0, 1)
      let lastYearEnd = new Date(new Date().getFullYear() - 1, 11, 31)

      return {
        'Այսօր': [todayStart, todayEnd],
        'Երեկ': [yesterdayStart, yesterdayEnd],
        'Այս շաբաթ': [thisWeekStart, thisWeekEnd],
        'Անցած շաբաթ': [lastWeekStart, lastWeekEnd],
        'Այս ամիս': [thisMonthStart, thisMonthEnd],
        'Անցած ամիս': [lastMonthStart, lastMonthEnd],
        'Այս եռամսյակ': [thisQuarterStart, thisQuarterEnd],
        'Անցած եռամսյակ': [lastQuarterStart, lastQuarterEnd],
        'Այս կիսամյակ': [thisSemesterStart, thisSemesterEnd],
        'Անցած կիսամյակ': [lastSemesterStart, lastSemesterEnd],
        'Այս տարի': [thisYearStart, thisYearEnd],
        'Անցած տարի': [lastYearStart, lastYearEnd],
        'Բոլորը': [allStart, todayEnd],
      }
    },
    service_name() {
      return key => {
        switch (key) {
          case 'pr':
            return 'Գույքահարկ';
          case 'co':
            return 'Բնապ. հարկ'
          case 'ts':
            return 'Տեխզննում'
        }
      }
    },
    service_amount() {
      return key => {
        switch (key) {
          case 'pr':
            return 150;
          case 'co':
            return 150
          case 'ts':
            return 250
        }
      }
    }
  },
  async created() {
    if (!store.hasModule(this.PAYMENTS_MODULE_NAME)) store.registerModule(this.PAYMENTS_MODULE_NAME, paymentsModule)
    this.getPayments()
    this.getQRStat()
  },
  methods: {

    activateInspectPayment (id) {
      this.$swal({
        title: '',
        text: 'Հաստատե՞լ գործողությունը',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Այո',
        cancelButtonText: 'Ոչ',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
          .then(result => {
            if (result.value) {
              this.$store.dispatch(`${this.PAYMENTS_MODULE_NAME}/setInspectDate`, id)
            }
          })
    },

    serialize(obj) {
      let str = []
      for (let p in obj) {
        if (obj.hasOwnProperty(p)) {
          if (typeof obj[p] !== 'undefined' && obj[p] !== null && obj[p] !== '') {
            str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]))
          }
        }
      }
      return str.join('&')
    },

    applyFilter() {
      this.pagination.current_page = 1
      this.getPayments()
      this.getQRStat()
    },

    openReceipt(order, service) {
      this.selectedService = service
      this.selectedPayment = order
      this.$bvModal.show('receipt-modal')
    },

    printReceipt() {
      // eslint-disable-next-line camelcase
      const print_window = window.open('', '', 'height=768,width=1024')

      print_window.document.write('<html>')
      print_window.document.write('<head>')
      print_window.document.write('<title></title>')
      print_window.document.write('<link rel="stylesheet" href="/assets/css/print.css" type="text/css"/>')
      print_window.document.write('</head>')
      print_window.document.write('<body>')
      print_window.document.write(document.getElementById('receipt-modal').innerHTML)
      print_window.document.write('</body>')
      print_window.document.write('</html>')
      setTimeout(() => {
        print_window.document.close()
        print_window.focus()
        print_window.print()
        print_window.close()
      }, 1000)
      return true
    },

    getPayments() {
      this.$vs.loading()
      let filters = { ...this.filters }
      filters.startDate = this.dateRange.startDate
      filters.endDate = this.dateRange.endDate
      const filterData = {
        page: this.pagination.current_page,
        ...filters
      }
      this.$store.dispatch(this.PAYMENTS_MODULE_NAME + '/getPayments', this.serialize(filterData))
          .then(() => {
            this.$vs.loading.close()
          })
          .catch((error) => {
            this.$root.$emit('getError', error)
            this.$vs.loading.close()
          })
    },

    getQRStat() {
      this.$store.dispatch(this.PAYMENTS_MODULE_NAME + '/getQRStat', this.serialize(this.filters))
    },
    openPaymentPage(payment) {
      window.open(payment.formUrl)
    },
    reloadPayment(payment) {
      this.$vs.loading()
      this.$store.dispatch(`${this.PAYMENTS_MODULE_NAME}/reloadPayment`, payment.id)
        .then(response => {
          this.$vs.loading.close()
          let message = 'Վճարման կարգավիճակ: '
          if (response.data.status === 1) {
            message += 'Ստուգվում է.'
          } else if (response.data.status === 2) {
            message += 'Հաստատվել է.'
          } else if (response.data.status === 3) {
            message += 'Մերժվել է.'
          }
          this.$swal(message)
        })
        .catch(() => {
          this.$vs.loading.close()
        })
    }
  },
}
</script>
<style lang="scss">
#idramPayments {
  .payments {
    display: flex;
    flex-direction: column;
    padding: 0 15px;
    .payment {
      display: flex;
      width: 100%;
      flex-flow: row;
      border: 1px solid #d8d6de;
      margin-bottom: 15px;
      min-height: 105px;
      .column {
        display: flex;
        justify-content: center;
        align-items: center;
        border-right: 1px solid #d8d6de;
        color: #000;
        font-size: 14px;
        font-weight: bold;
        &:nth-child(1) {
          width: 15%;
        }
        &:nth-child(2) {
          width: 15%;
        }
        &:nth-child(3) {
          width: 20%;
        }
        &:nth-child(4) {
          width: 30%;
        }
        &:nth-child(5) {
          width: 10%;
        }
        &:nth-child(6) {
          width: 10%;
        }
        &:last-child {
          border-right: none;
        }
        .user-info {
          display: flex;
          width: 100%;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .item {
            opacity: 1;
          }
        }
        .services {
          width: 100%;
          display: flex;
          flex-direction: column;
          height: 100%;
          .service {
            display: flex;
            flex-flow: row;
            border-bottom: 1px solid #d8d6de;
            height: 100%;
            min-height: 35px;
            justify-content: center;
            align-items: center;
            &:last-child {
              border-bottom: none;
            }
            .item {
              border-right: 1px solid #d8d6de;
              display: flex;
              align-items: center;
              justify-content: center;
              height: 100%;
              &:nth-child(1) {
                width: 50%;
              }
              &:nth-child(2) {
                width: 25%;
              }
              &:nth-child(3) {
                width: 25%;
              }
              &:last-child {
                border-right: none;
              }
              .receiptIcon {
                cursor: pointer;
                font-size: 16px;
              }
            }
          }
        }
      }
    }
  }
  .filter-column {
    min-width: 200px;
  }
  .qr-stat {
    display: flex;
    justify-content: space-evenly;
    margin: 2rem 0;
    .stat {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .title {
        color: #000;
        font-weight: bold;
        //border-bottom: 1px solid #eee;
        padding-bottom: 4px;
      }
      .info {
        display: flex;
        flex-direction: column;
        //border: 1px solid #ccc;
        //margin-top: 10px;
        width: 100%;
        border-radius: 6px;
        padding: 15px 0;
        justify-content: center;
        align-items: center;
        .info-col {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 50%;
          color: #000;
          font-weight: bold;
          min-height: 35px;
          i {
            margin-right: 10px;
          }
          .srvsc {
            border-top: 1px solid #eee;
            border-bottom: 1px solid #eee;
            padding: 10px 0;
          }
        }
      }
    }
  }
}

@font-face{
  font-family:YerevanyanBold;
  src:url('/assets/fonts/YerevanyanBold.otf')
}


#receipt-modal {

  .modal-header {
    display: none;
  }
  .receipt-close {
    cursor: pointer;
    font-size: 36px;
    position: absolute;
    right: 20px;
    color: #000;
  }
  opacity: 1;
  .receipt-header {
    display: flex;
    flex-direction: column;
    border-bottom: 2px solid #000;
    padding-bottom: 5px;
    .bank-logo {
      margin: 20px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 150px;
      }
    }
  }
  .receipt-body {
    padding: 20px 0;
    font-weight: bold;
  }
  .receipt-station {
    border-top: 1px dashed #000;
    padding: 15px 0;
    font-weight: bold;
  }
  .receipt-service {
    border-top: 1px dashed #000;
    padding: 10px 0;
    font-weight: bold;
  }
  .receipt-total {
    border-top: 2px solid #000;
    padding-top: 5px;
  }
  .receipt-footer {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    .print {
      cursor: pointer;
      font-size: 14px;
      color: #000;
    }
  }
  .receipt-column {
    color: #000;
    font-size: 11px;
    display: flex;
    justify-content: space-between;
    .info {
      opacity: 1;
    }
  }
}


#idramPayments .table-responsive {
  max-height: calc(var(--vh, 1vh)*100 - 34rem);
  min-height: calc(var(--vh, 1vh)*100 - 34rem);
}
.table-footer {
  position: relative;
  top: -10px;
  left: 15px;
}
.inspect-status {
  cursor: pointer;
  &.success {
    color: var(--success)
  }
  &.danger {
    color: var(--danger)
  }
}
.filters {
  .paymentsPicker {
    .daterangepicker {
      left: 37%!important;
      top: initial !important;
      bottom: initial!important;
      .drp-buttons {
        display: flex;
        width: 100%;
        float: right;
      }
    }
  }
}
.srvsc {
  display: flex;
  flex-direction: column;
  width: 400px;
  justify-content: center;
  align-items: center;
}
</style>

